<template>
    <div
        class="top-scroll"
        :class="{ active: active }"
    >
        <b-button
            aria-label="scroll"
            variant="secondary"
            class="fill icon sm"
            @click="scrollToTop()"
        >
            <chevron-up-icon />
        </b-button>
    </div>
</template>
<script>
    import ChevronUpIcon from 'vue-feather-icons/icons/ChevronUpIcon';
    import { mapMutations } from 'vuex';
    export default {
        name: 'ScrollToTop',
        components: {
            ChevronUpIcon,
        },
        data() {
            return {
                scrolled: false,
                active: false,
            };
        },
        methods: {
            ...mapMutations('general', ['setScrollBasedComponentVisibility']),
            handleScroll() {
                if (window.scrollY > 100 && !this.scrolled) {
                    this.setScrollBasedComponentVisibility(true);
                }

                if (window.scrollY > 800 && !this.scrolled) {
                    this.scrolled = true;
                    this.active = true;
                    this.setScrollBasedComponentVisibility(true);
                } else if (window.scrollY <= 800 && this.scrolled) {
                    this.scrolled = false;
                    this.active = false;
                }
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
    };
</script>
<style scoped lang="scss">
.top-scroll {
  opacity: 0;
  position: fixed;
  bottom: 100px;
  right: 32px;
  transition: opacity 300ms ease-in-out;
  .btn {
    background-color: $primary-75;
    &.fill {
      box-shadow: rgb(0 0 0 / 20%) 0px 1px 4px, rgb(0 0 0 / 20%) 0px 4px 22px;
      &:focus-visible,
      &:focus {
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 4px, rgb(0 0 0 / 20%) 0px 4px 22px;
        border: none !important;
        outline: none;
      }
      @include media-breakpoint-up(md) {
        &:hover {
          background-color: $primary-75;
        }
      }
    }
  }
  &.active {
    transition: opacity 300ms ease-in-out;
    opacity: 1;
  }
  @include media-breakpoint-down(md) {
    right: 16px;
  }
}
</style>
